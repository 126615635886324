<template>
  <Page>
    <template v-slot:content>
      <div class="image" v-if="content">
        <Title :label="`A sua imagem com o UUID - ${UUID}`"></Title>
        <img :src="'data:image/png;base64,' + content" />
      </div>
      <div class="error" v-if="error">
        <Title :label="`Erro a encontrar a imagem com UUID - ${UUID}`"></Title>
        <Subtitle :label="error"></Subtitle>
      </div>
    </template>
  </Page>
</template>

<script>
import { Title, Subtitle } from "@sc/ds-ui-component-library";
import { Page } from "./../../templates";
import { getImage } from "../../../api/images";

export default {
  name: "ImageUUID",
  components: {
    Page,
    Title,
    Subtitle
  },
  data() {
    return {
      UUID: "",
      content: "",
      error: ""
    };
  },
  async mounted() {
    this.UUID = this.$route.params.id;
    this.content = await this.getImageBase64ByUUID(this.UUID);
  },
  methods: {
    async getImageBase64ByUUID(UUID) {
      let response;

      try {
        response = await getImage(UUID);
        response = response.data.data.content;
      } catch (e) {
        response = "";
        this.error = "Não foi possível encontrar esta imagem";
      }

      return response;
    }
  }
};
</script>

<style scoped lang="css">
.image,
.error {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 30px;
  width: auto;
}

.image > img {
  object-fit: none;
}
</style>
