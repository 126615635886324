import httpClient from "./httpClient";

const IMAGES = "/retake/image";
const companyId = 1;

function getImage(UUID) {
  return httpClient.get(IMAGES + "/" + UUID, {
    headers: {
      companyId: companyId
    }
  });
}

export { getImage };
